import React from 'react'
import {Link} from 'gatsby'

import SimplePage from '../components/SimplePage'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SimplePage>
      <h1>404: non trovata</h1>
      <p>Oops, pagina non trovata.</p>
      <p></p>
      <Link to="/">Torna alla Home</Link>
    </SimplePage>
  </Layout>
)

export default NotFoundPage
